export const useGTag = () => {
     const item_list_id = "gold_coin_package";
     const item_list_name = "Gold Coin Package";
     const currency = "USD";

     // Push view_item_list event to the dataLayer
     const gTagViewItemList = (items) => {
          console.log("gTagViewItemList works");
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
               event: "view_item_list",
               ecommerce: {
                    item_list_id: item_list_id,
                    item_list_name: item_list_name,
                    items: items
               }
          });
     };

     // Push select_item event to the dataLayer
     const gTagSelectItemList = (item) => {
          console.log("gTagSelectItemList works");

          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
               event: "select_item",
               ecommerce: {
                    item_list_id: item_list_id,
                    item_list_name: item_list_name,
                    items: [item]
               }
          });
     };

     // Push begin_checkout event to the dataLayer
     const gTagBeginCheckout = (value, item) => {
          console.log("gTagBeginCheckout works");

          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
               event: "begin_checkout",
               ecommerce: {
                    currency: currency,
                    value: value,
                    items: [item]
               }
          });
     };

     // Push add_payment_info event to the dataLayer
     const gTagAddPaymentInfo = (value, payment_type, item) => {
          console.log("gTagAddPaymentInfo works");

          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
               event: "add_payment_info",
               ecommerce: {
                    currency: currency,
                    value: value,
                    payment_type: payment_type,
                    items: [item]
               }
          });
     };

     // Push purchase event to the dataLayer
     const gTagPurchase = (
          transaction_id,
          value,
          item,
          username,
          first_deposit
     ) => {
          console.log("gTagPurchase works");

          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
               event: "purchase",
               ecommerce: {
                    transaction_id: transaction_id,
                    currency: currency,
                    value: value,
                    items: [item]
               },
               userId: username
          });

          fbq("track", "Purchase", { currency: currency, value: value });

          if (!first_deposit) {
               dataLayer.push({
                    event: "checkout",
                    ecommerce: {
                         value: value,
                         currency: currency,
                         username: username,
                         transaction_id: transaction_id
                    }
               });
          }

          ttd_dom_ready(function () {
               if (typeof TTDUniversalPixelApi === "function") {
                    var universalPixelApi = new TTDUniversalPixelApi();
                    universalPixelApi.init(
                         "nqpc8db",
                         ["qage55c"],
                         "https://insight.adsrvr.org/track/up",
                         {
                              v: value,
                              vf: currency
                         }
                    );
               }
          });
     };

     // Push select_item event to the dataLayer
     const gTagUserID = (username) => {
          console.log("gTagUserID works");

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
               event: "login",
               userId: username
          });
     };

     const gTagUserSignup = (username) => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
               event: "register",
               userId: username
          });
     };

     return {
          gTagViewItemList,
          gTagSelectItemList,
          gTagBeginCheckout,
          gTagAddPaymentInfo,
          gTagPurchase,
          gTagUserID,
          gTagUserSignup
     };
};

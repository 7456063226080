const PageNotFound = () => import("pages/404/index.vue");
// const Home = () => import('pages/home/index.vue');
const Lobby = () => import("pages/lobby/index.vue");
const Login = () => import("pages/auth/login.vue");
const Register = () => import("pages/auth/register.vue");
const Forgot = () => import("pages/auth/forgot.vue");
const Promotion = () => import("pages/promotion/index.vue");
const PromotionDetail = () => import("pages/promotionDetail/index.vue");
// const Provider = () => import('pages/provider/index.vue');
const Support = () => import("pages/support/index.vue");
const Redeem = () => import("pages/redeem/index.vue");
const Code = () => import("pages/code/index.vue");
const About = () => import("pages/about/index.vue");
const ResetPassword = () => import("pages/reset/index.vue");
const Terms = () => import("pages/tnc/index.vue");
const MooziRules = () => import("pages/mooziRules/index.vue");
const PlayerSafety = () => import("pages/playerSafety/index.vue");
const Sweepstakes = () => import("pages/sweeptakes/index.vue");
const Privacy = () => import("pages/privacy/index.vue");
const GoldCoinShop = () => import("pages/goldCoinShop/index.vue");
const Notification = () => import("pages/notification/index.vue");
const Account = () => import("pages/account/index.vue");
const Thanks = () => import("pages/thanks/index.vue");
const GameDetails = () => import("pages/gameDetails/index.vue");
const Play = () => import("pages/play/index.vue");
// const Test = () => import("pages/test/index.vue");
const Signature = () => import("pages/signature/index.vue");
import auth from "middleware/auth.js";
import guest from "middleware/guest.js";

const routes = [
     {
          meta: {
               layout: "basic"
          },
          path: "/:pathMatch(.*)*",
          name: "NotFound",
          component: PageNotFound
     },
     {
          path: "/",
          redirect: "/lobby"
     },
     {
          meta: {
               layout: "default"
          },
          path: "/lobby",
          name: "lobby",
          component: Lobby
     },
     // {
     //      meta: {
     //           layout: "default"
     //      },
     //      path: "/try",
     //      name: "try",
     //      component: Test
     // },
     {
          meta: {
               layout: "default",
               middleware: guest
          },
          path: "/signin",
          name: "login",
          component: Login
     },
     {
          meta: {
               layout: "default",
               middleware: guest
          },
          path: "/signup",
          name: "register",
          component: Register
     },
     {
          meta: {
               layout: "default",
               middleware: guest
          },
          path: "/forgot",
          name: "forgot",
          component: Forgot
     },
     // {
     //      meta: {
     //           layout: 'default',
     //      },
     //      path: "/home",
     //      name: "home",
     //      component: Home,
     // },
     {
          meta: {
               layout: "default"
          },
          path: "/promotion",
          name: "promotion",
          component: Promotion
     },
     {
          meta: {
               layout: "default"
          },
          path: "/promotion/detail/:title",
          name: "promotionDetail",
          component: PromotionDetail
     },
     {
          meta: {
               layout: "default"
          },
          path: "/games/detail/:id?",
          name: "gameDetails",
          component: GameDetails
     },
     // {
     //      meta: {
     //           layout: 'default'
     //      },
     //      path: "/provider/:name",
     //      name: "provider",
     //      component: Provider
     // },
     {
          meta: {
               layout: "default"
          },
          path: "/support",
          name: "support",
          component: Support
     },
     {
          meta: {
               layout: "default",
               middleware: auth
          },
          path: "/code",
          name: "code",
          component: Code
     },
     {
          meta: {
               layout: "default"
          },
          path: "/about-us",
          name: "about-us",
          component: About
     },
     // {
     //      meta: {
     //           layout: 'default'
     //      },
     //      path: "/submit-a-request",
     //      name: "submit-a-request",
     //      component: Request
     // },
     {
          meta: {
               layout: "default"
          },
          path: "/reset/:token?",
          name: "reset",
          component: ResetPassword
     },
     {
          meta: {
               layout: "default"
          },
          path: "/terms-of-use",
          name: "terms-of-use",
          component: Terms
     },
     {
          meta: {
               layout: "default"
          },
          path: "/moozi-rules",
          name: "moozi-rules",
          component: MooziRules
     },
     {
          meta: {
               layout: "default"
          },
          path: "/player-safety",
          name: "player-safety",
          component: PlayerSafety
     },
     {
          meta: {
               layout: "default"
          },
          path: "/sc-coins",
          name: "sc-coins",
          component: Sweepstakes
     },
     {
          meta: {
               layout: "default"
          },
          path: "/privacy",
          name: "privacy",
          component: Privacy
     },
     {
          meta: {
               layout: "default"
          },
          path: "/gold-coin-shop",
          name: "gold-coin-shop",
          component: GoldCoinShop
     },
     {
          meta: {
               layout: "default",
               middleware: auth
          },
          path: "/redeem",
          name: "redeem",
          component: Redeem
     },
     {
          meta: {
               layout: "default",
               middleware: auth
          },
          path: "/notification",
          name: "notification",
          component: Notification
     },
     {
          meta: {
               layout: "default",
               middleware: auth
          },
          path: "/account",
          name: "account",
          component: Account
     },
     {
          meta: {
               layout: "default",
               middleware: auth
          },
          path: "/play/:slug/:id/:launchcode/:type",
          name: "play",
          component: Play,
          props: true
     },
     {
          meta: {
               layout: "default"
          },
          path: "/thanks/:gc/:sc",
          name: "thanks",
          component: Thanks,
          props: true
     },
     {
          meta: {
               layout: "default"
          },
          path: "/thanks/:gc/:sc",
          name: "congratulations",
          component: Thanks,
          props: true
     },
     {
          meta: {
               layout: "default",
               middleware: auth
          },
          path: "/vip-form",
          name: "vip-form",
          component: Signature
     }
];

export default routes;
